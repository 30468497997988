import React from 'react'
import { branch, renderNothing } from 'recompose'
import styled from '@emotion/styled'
import Img, { FluidObject, GatsbyImageProps } from 'gatsby-image'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import Meta from './metadata/PageMeta'

export interface SiteMeta {
  siteMetadata: {
    siteTitle: string
    siteTitleShort: string
    siteUrl: string
    siteLogo: string
    facebookAppId: string
    facebookAdmins: string[]
    twitterUser: string
  }
}

export interface PageContent {
  path: string
  title: string
  description: string
  keywords: string[]
  markdown: {
    body: string
    frontmatter: {
      image: {
        alt: string
        file: {
          meta: {
            fluid: FluidObject
          }
        }
      }
      bannerImage: {
        alt: string
        file: {
          hero: {
            fluid: FluidObject
          }
        }
      }
    }
  }
}

// const HeroImg = branch(({ sizes }: GatsbyImageProps) => !sizes, renderNothing)(Img)
const HeroImg = Img

const Inner = styled.div`
  margin: 0 auto;
  padding: 0 1em;
  max-width: 52em;
`

export interface DefaultTemplateProps {
  page: {}
  heroImage?: GatsbyImageProps
  body: string
  children?: React.ReactNode
  before?: React.ReactNode
  after?: React.ReactNode
}

const DefaultTemplate = ({
  page,
  heroImage,
  body,
  children,
  before,
  after,
}: DefaultTemplateProps) => (
  <>
    <Meta page={page} />

    {heroImage && <HeroImg {...heroImage} />}

    {before}

    <Inner>
      <MDXRenderer>{body}</MDXRenderer>
      {children}
    </Inner>

    {after}
  </>
)

interface DefaultTemplateData {
  site: SiteMeta
  page: PageContent
}

DefaultTemplate.selectProps = (props: {
  data: DefaultTemplateData
}): Pick<DefaultTemplateProps, 'page' | 'body' | 'heroImage'> => {
  return {
    page: props.data.page,
    body: props.data.page.markdown.body,
    heroImage: props.data.page.markdown.frontmatter.bannerImage
      ? {
          ...props.data.page.markdown.frontmatter.bannerImage.file.hero,
          alt: props.data.page.markdown.frontmatter.bannerImage.alt,
        }
      : undefined,
  }
}

export default DefaultTemplate
