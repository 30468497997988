import React, { useMemo } from 'react'
import { graphql } from 'gatsby'

import DefaultTemplate from '../components/DefaultTemplate'

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const LayoutLocation = ({ data }) => {
  const defaultTemplateProps = useMemo(() => DefaultTemplate.selectProps({ data }), [data])

  return (
    <>
      <DefaultTemplate {...defaultTemplateProps} />
    </>
  )
}

export default LayoutLocation

export const pageQuery = graphql`
  query($path: String!) {
    site {
      ...SiteMeta
    }
    page(path: { eq: $path }) {
      ...PageContent
      markdown {
        frontmatter {
          gallery {
            alt
            file {
              childImageSharp {
                fluid(maxWidth: 140, maxHeight: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
