import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'

import { useSiteMetadata } from '../../queries'

const Meta = ({
  ogType = 'website',
  twitterCardType = 'summary_large_image',
  title,
  imageSrc,
  path,
  siteUrl,
  siteTitle,
  siteTitleShort,
  description,
  keywords,
  twitterUser,
  facebookAppId,
  facebookAdmins,
}) => (
  <Helmet>
    <title>{title ? `${title} | ${siteTitleShort}` : siteTitle}</title>
    <meta name="description" content={description} />
    <meta name="keywords" content={keywords ? keywords.join(', ') : ''} />
    <meta name="image" content={imageSrc || ''} />

    {/* og */}
    <meta property="og:url" content={`${siteUrl}${path}`} />
    <meta property="og:type" content={ogType || ''} />
    <meta property="og:title" content={title || siteTitle || ''} />
    <meta property="og:description" content={description || ''} />
    <meta property="og:image" content={imageSrc || ''} />

    {/* facebook */}
    <meta property="fb:app_id" content={facebookAppId || ''} />
    {facebookAdmins
      ? facebookAdmins.map(admin => <meta key={admin} property="fb:admin" content={admin} />)
      : null}

    {/* twitter */}
    <meta name="twitter:card" content={twitterCardType || ''} />
    <meta name="twitter:creator" content={twitterUser || ''} />
    <meta name="twitter:title" content={title || siteTitle || ''} />
    <meta name="twitter:description" content={description || ''} />
    <meta name="twitter:image" content={imageSrc || ''} />
  </Helmet>
)

Meta.propTypes = {
  title: PropTypes.string.isRequired,
  imageSrc: PropTypes.string,
  description: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  keywords: PropTypes.arrayOf(PropTypes.string),
  siteUrl: PropTypes.string.isRequired,
  siteTitle: PropTypes.string.isRequired,
  siteTitleShort: PropTypes.string.isRequired,

  facebookAppId: PropTypes.string.isRequired,
  facebookAdmins: PropTypes.arrayOf(PropTypes.string),
  twitterUser: PropTypes.string.isRequired,

  ogType: PropTypes.string,
  twitterCardType: PropTypes.string,
}

Meta.defaultProps = {
  imageSrc: undefined,
  keywords: undefined,
  facebookAdmins: undefined,
  ogType: undefined,
  twitterCardType: undefined,
}

const PageMetadata = ({ page }) => {
  const siteMetadata = useSiteMetadata()

  const { path, title, description, keywords } = page

  const imageSrc = page.markdown.frontmatter.image
    ? page.markdown.frontmatter.image.file.meta.fluid.src
    : null

  return <Meta {...siteMetadata} {...{ path, title, description, keywords, imageSrc }} />
}

PageMetadata.propTypes = {
  page: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
}

export default PageMetadata
